.management_hero_container {
  height: 500px;
  overflow: hidden;
}

.management-row-short {
  width: 65%;
}

.management_hero_img {
  margin-top: -110px;
  width: 100%
}

.management_adjustment_layer {
  /* background-color: black; */
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0.4;
}

.management_hero_text {
  position: absolute;
  top: 17rem;
  left: 50%;
  font-size: 3rem;
  color: rgb(77, 77, 77);
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.management_rows {
  margin-top: 65px;
  justify-content: center;
}

.management_rows-short-nm {
  justify-content: center;
}

.hr_width {
  width: 50%;
  margin-top: 7px;
  margin-bottom: 7px;
}

.management_p_nomargin {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 0.8em;
  color: rgb(133, 133, 133);
  font-weight: 300;
}

.management_title_nomargin {
  margin-bottom: 0 !important;
  font-size: 1.2rem !important;
}

.card {
  transition: 0.4s ease 0s;
}

.card:hover {
  box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.management_bottom_row_margin {
  margin-right: 100px !important;
  margin-left: 100px !important;
}

.management-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 15px;
  max-width: 800px;
  margin: auto;
  margin-top: 65px;
  padding: 0 20px
}

@media (min-width: 576px) {
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 7px !important;
    margin-bottom: 12px !important;
    margin-left: 7px !important;
  }
}

@media (max-width: 576px) {
  .management-row-short {
    width: 100%;
  }
  .management-grid {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 15px;
    max-width: 800px;
    margin: auto;
    margin-top: 65px;
    padding: 0 20px
  }
}

@media only screen and (max-width: 600px) {
  .management_underline {
    margin-left: 42.5%;
  }
}

@media only screen and (max-width: 888px) {
  .management_hero_container {
    height: auto;
    overflow: hidden;
  }
}

@media only screen and (max-width: 640px) {
  .management_hero_text {
    top: 9rem;
    font-size: 2rem;
  }
}

@media only screen and (max-width: 1542px) {
  .management_hero_img {
    margin-top: 75px;
  }
}

@media only screen and (max-width: 768px) {
  .management_bottom_row_margin {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}