.custom-toggler-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.custom-toggler-black .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.nav_clear {
  background-color: rgba(0, 0, 0, 0) !important;
  z-index: 101;
  box-shadow: none !important;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.nav_color {
  background-color: rgb(255, 255, 255) !important;
  border-bottom: 1px solid rgb(236, 236, 236);
  z-index: 101;
  /*box-shadow: none !important;
  */transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.nav_link_white {
  color: white !important;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.nav_link_black {
  color: black !important;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.nav_link_black:hover {
  color: #2c5ea8 !important;
  
}

.p_dropdown_nav {
  margin: 0;
  text-align: left;
  padding-top: 18px;
}

.nav_spans {
  color: black;
}

.nav_spans:hover {
  color: #2c5ea8;
}

.menu {
  border: 1px solid rgb(219, 219, 219);
  border-radius: 3px;
  z-index: 5;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  display: block;
  position: absolute;
  background-color: white;
  margin-top: 8px;
}

.nav_drop_span {
  color: black;
}

.main-img {
  width: 100%;
  height: auto;
}

.navbar-brand {
  color: white !important;
}

.navbar-brand:hover {
  color: white;
}

/* .navbar-light .navbar-nav .nav-link {
  color: white !important;
} */

.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(209, 209, 209);
}

.logo-img {
  width: 200px;
  height: auto;
}
