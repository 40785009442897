#hero-scfm-link {
  color: white;
  text-decoration: underline;
}

.carousel_container {
  width: 100%;
  height: auto;
  /* background-color: rgb(31, 121, 195); */
}

.hero_text_overlay {
  position: absolute;
  right: 15%;
  /* bottom: 26rem; */
  top: 30%;
  left: 5% !important;
  z-index: 100;
  color: rgb(255, 255, 255);
  text-align: center;
}

.hero_arrow {
  position: absolute;
  right: 0% !important;
  bottom: 0rem;
  left: 0%;
  z-index: 100;
  color: rgb(255, 255, 255);
  text-align: center;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.hero_arrow_hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
  position: absolute;
  right: 0% !important;
  bottom: 0rem;
  left: 0%;
  z-index: 100;
  color: rgb(255, 255, 255);
  text-align: center;
}

.hero_video {
  z-index: -10 !important;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 5% !important;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 17% !important;
  color: #fff;
  text-align: center;
}

.hero_arrow_row {
  margin-top: 10%;
}

.hero_video_header {
  text-align: left;
}

.hero_video_header_title {
  font-size: 3.5rem !important;
  text-transform: uppercase;
  font-weight: 600;
}

.hero_video_header_text {
  font-size: 1.4rem;
}

.hero_video_header_text_bottom {
  font-size: 1.5rem
}

.hero_arrow_text {
  color: #142d53;
}

.hero_nomargin {
  margin-bottom: -7px;
}

.chevron_down {
  color: white;
  -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.0s; /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
}

.chevron_down_browser {
  color: white;
  font-size: 35px;
  -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.0s; /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
  0% {
    color: rgb(83, 115, 255);
    left: 0px;
    top: 0px;
  }
  50% {
    color: white;
    left: 200px;
    top: 200px;
  }
  100% {
    color: rgb(83, 115, 255);
    left: 0px;
    top: 0px;
  }
}

/* Standard syntax */
@keyframes example {
  0% {
    color: rgb(83, 115, 255);
    left: 0px;
    top: 0px;
    transform:translateY(0%);
  }
  50% {
    color: white;
    left: 200px;
    top: 200px;
    transform:translateY(-35%);
  }
  100% {
    color: rgb(83, 115, 255);
    left: 0px;
    top: 0px;
    transform:translateY(0%);
  }
}

.hero_mobile_container {
  display: grid;
}
.hero_mobile_image,
.hero_mobile_arrow {
  grid-area: 1/1;
  object-fit: cover;
}


.hero_mobile_arrow {
  margin-left: 49%;
  margin-top: 80vh;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.hero_mobile_arrow_hide {
  margin-left: 49%;
  margin-top: 80vh;
  grid-area: 1/1;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

@media only screen and (max-width: 1100px) {
  .hero_text_overlay {
    display: none !important;
  }
}

/* @media only screen and (max-width: 1470px) {
  .hero_text_overlay {
    bottom: 20rem;
  }
} */

@media only screen and (max-width: 768px) {
  .hero_nomargin {
    margin-bottom: 0;
  }
}
