* {
  margin: 0;
  padding: 0;
}

.no-margin {
  margin: 0;
}

.align_center {
  display: flex;
  justify-content: center;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ul_margin {
  padding-left: 30px;
}

.no_margin_p {
  margin-bottom: 0
}

.align_right {
  text-align: right;
}

.align_center {
  text-align: center;
}

.xtra_margin_bottom {
  margin-bottom: 25px;
}