.blurb_div {
    padding-top: 10px;
    padding-bottom: 15px;
    text-align: center;
}

.blurb_info {
    font-size: 1.2em;
    margin: 0;
    padding: 10px 0 10px 0
}

#blurb-link {
  color: black;
  text-decoration: underline;
  text-align: center;
}

.blurb_info_bottom {
  font-size: 1.3rem;
  margin-bottom: 0;
}

.blurb_info_bottom_link {
  font-size: 1.3rem;
  margin-top: 0;
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 1000px) {
    .blurb_div {
      display: none !important;
    }
  }
  