/* ============================ */
/* People SVG */
/* ============================ */
.st0 {
  fill: #00335f;
}

svg {
  height: 150px;
  width: 150px;
}

#svg_circle {
}

#svg_people {
    fill: #00335f;
}

/* ================================================================= */
/* Circle animation */
/* ================================================================= */
.svg_circle {
  animation-duration: 1s;
  animation-name: animateCircle;
}

@keyframes animateCircle {
  from {
    transform: translateX(0) translateY(25px);
    -webkit-transform: translateX(0) translateY(25px);
    -moz-transform: translateX(0) translateY(25px);
    -ms-transform: translateX(0) translateY(25px);
    -o-transform: translateX(0) translateY(25px);
    opacity: 0;
}
  to {
    transform: translateX(0px) translateY(0px);
    -webkit-transform: translateX(0px) translateY(0px);
    -moz-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    -o-transform: translateX(0px) translateY(0px);
    opacity: 1;
}
}

/* =================================================================== */
/* Money animations */
/* =================================================================== */
.svg_image {
    animation-duration: 1.2s;
    animation-name: animatePeople;
  }
  
  @keyframes animatePeople {
    from {
      transform: translateX(-45px) translateY(0);
      -webkit-transform: translateX(-45px) translateY(0);
      -moz-transform: translateX(-45px) translateY(0);
      -ms-transform: translateX(-45px) translateY(0);
      -o-transform: translateX(-45px) translateY(0);
      opacity: 0;
  }
    to {
      transform: translateX(0px) translateY(0px);
      -webkit-transform: translateX(0px) translateY(0px);
      -moz-transform: translateX(0px) translateY(0px);
      -ms-transform: translateX(0px) translateY(0px);
      -o-transform: translateX(0px) translateY(0px);
      opacity: 1;
  }
  }