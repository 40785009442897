.footer_row {
  background: url("../../assets/images/FOOTER.jpg") no-repeat 50%;
  background-color: #142d53;
  color: white;
  padding: 45px 0 25px 0;
  margin-top: 85px;
  border-top: 12px solid #308db8;
  background-size: cover;
}

.footer_headers {
  font-weight: 500;
  color: rgb(236, 236, 236);
}

.footer_link {
  color: rgb(179, 179, 179) !important;
}

.footer_link:hover {
  color: white !important;
}

.footer_hor_rule {
  width: 60%;
  background-color: rgb(156, 156, 156);
  /* height: 0.4px; */
}

.footer_paras {
  margin-bottom: 0.2em;
  font-size: 0.9em;
  color: rgb(179, 179, 179);
}

.footer_logo_col {
  padding-right: 50px !important;
}

.footer_lower_hr {
  background-color: rgb(156, 156, 156);
  margin-top: 1.2rem;
  /* height: 0.8px; */
}

.footer_span_start {
  padding-right: 25px;
  font-size: 0.8rem;
}

.footer_privacy_span {
  border-right: 1px solid rgb(156, 156, 156);
  padding-right: 10px;
  font-size: 0.8rem;
}

.footer_term_span {
  /* border-right: 1px solid rgb(156, 156, 156); */
  padding-right: 10px;
  margin-left: 10px;
  font-size: 0.8rem;
}

.footer_brokerCheck_span {
  padding-left: 10px;
  font-size: 0.8rem;
  height: 20px;
}

.footer_broker_image {
  width: 6rem;
  height: auto;
}

.footer_privacy_span,
.footer_term_span:hover {
  cursor: pointer;
}

.footer_bottom_footer_no_padding {
  padding-left: 0 !important;
}

.footer_quick_links {
  padding-left: 4rem !important;
}

@media only screen and (max-width: 600px) {
  .footer_image {
    padding-bottom: 1rem;
  }
  .footer_logo_col {
    padding-right: 55px !important;
    padding-left: 55px !important;
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: 990px) {
  .footer_quick_links {
    padding-left: 15px !important;
  }
  .footer_lower_hr {
    margin-top: 20px;
  }
}
