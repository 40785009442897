.profile_top_margin {
    margin-top: 130px; 
}

.profile_image_row {
    margin-bottom: 15px;
    max-width: 100%;
    height: auto;
}

.profile_image_container {
    padding: 0 !important;
}

.profile_header {
    margin-bottom: 3px;
}

.profile_title {
    font-size: 0.9rem;
    color: rgb(145, 145, 145);
    margin-bottom: 30px;
}

.profile_no_padding {
    padding-left: 0 !important;
}
