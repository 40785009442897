.jobs_container {
    margin-top: 3rem;
}

.job-openings_row {
    display: flex;
    justify-content: center;
}

.btn-u {
    border: 0;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    padding: 9px 9px;
    position: relative;
    background: #142d53;
    white-space: nowrap;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    width: 40%;
 }

 .btn-u:hover {
    background: #265292;
    color: white;
 }

 .specialist_logo {
     display: inline-block;
 }

 .specialist_resume_button_container {
     display: flex;
     align-items: flex-end;
 }

 .jobs_bold {
     font-weight: 400;
 }

 .jobs_jobtitle_margin {
     margin-bottom: 0;
 }

 @media only screen and (max-width: 992px ) {
    .jobs_container {
        margin-top: 0.5rem;
    }
    .specialist_resume_button_container {
        margin-bottom: 14px;
        padding-left: 0 !important;
    }
    .btn-u {
        width: 60%;
    }
 }