.dist_container {
  /* background-color: #142d53; */
  color: white;
  padding-bottom: 45px;
}

.dist_header {
  margin-top: 55px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #4b9cc1;
}

.dist_info_para {
  margin-top: 25px;
  margin-bottom: 45px;
  font-size: 1.2em;
  color: black;
}

.dist_para_color {
  color: black;
}

.dist_images_row {
  margin-bottom: 65px;
}

.dist-img {
  max-width: 120px;
}

.dist_checks {
  text-align: justify;
}

.dist_check_row {
  padding-bottom: 45px;
}

.dist_image_hover_effect {
  transition: all 0.2s ease-in-out;
  margin-bottom: 15px;
}

.dist_large_text {
  font-size: 2rem;
  font-weight: 600;
  color: #4b9cc1;
}

@media only screen and (max-width: 600px) {
  .dist_checks {
    padding-left: 22% !important;
  }
}
