.assets_hero_container {
  height: 500px;
  overflow: hidden;
}

.asset_hero_image {
  object-fit: contain;
  width: 100%;
}

.assets_adjustment_layer {
  background-color: black;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0.7;
}

.assets_hero_text {
  position: absolute;
  top: 17rem;
  left: 50%;
  font-size: 3rem;
  color: rgb(77, 77, 77);
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.assets_container {
  margin-left: 25% !important;
  margin-right: 25% !important;
  margin-top: 55px;
  font-weight: 500;
  font-size: 1.1rem;
}

.div_1 {
  height: 500px;
  background-image: url("/assets/DATACENTER-BANNER.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  z-index: 2;
}

.div_1 {
  clip-path: polygon(
    0 18%,
    /* left top */ 100% 0,
    /* right top */ 100% 100%,
    /* right bottom */ 0 calc(100% - 6vw) /* left bottom */
  );
}

.div_2 {
  background-color: white;
  height: auto;
  margin-top: -250px;
  background-color: rgba(255, 255, 255, 0);
  /* max-width: 100%; */
  margin-left: 25% !important;
  margin-right: 25% !important;
  z-index: 3;
}

.asset_cards {
  display: flex;
  justify-content: center;
}

.div_3 {
  background-color: #7395AE;
  height: 600px;
  margin-top: -450px;
  z-index: 1;
  content: "";
}

.div_3 {
  clip-path: polygon(
    0 60%,
    /* left top */ 100% 0,
    /* right top */ 100% 82%,
    /* right bottom */ 0 100% /* left bottom */
  );
}

.div_4 {
  height: 500px;
  background-image: url("/assets/HEALTHCARE-BANNER.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  z-index: 2;
}

.div_4 {
  clip-path: polygon(
    0 18%,
    /* left top */ 100% 0,
    /* right top */ 100% 100%,
    /* right bottom */ 0 calc(100% - 6vw) /* left bottom */
  );
}

.div_5 {
  background-color: white;
  height: auto;
  margin-top: -250px;
  background-color: rgba(255, 255, 255, 0);
  /* max-width: 100%; */
  margin-left: 25% !important;
  margin-right: 25% !important;
  z-index: 3;
}

.div_6 {
  background-color: #5D5C61;
  height: 500px;
  margin-top: -350px;
  z-index: 1;
  content: "";
}

.div_6 {
  clip-path: polygon(
    0 60%,
    /* left top */ 100% 0,
    /* right top */ 100% 82%,
    /* right bottom */ 0 100% /* left bottom */
  );
}

.div_7 {
  height: 500px;
  background-image: url("/assets/RENEWABLE-BANNER.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  z-index: 2;
}

.div_7 {
  clip-path: polygon(
    0 18%,
    /* left top */ 100% 0,
    /* right top */ 100% 100%,
    /* right bottom */ 0 calc(100% - 6vw) /* left bottom */
  );
}

.div_8 {
  background-color: white;
  height: auto;
  margin-top: -250px;
  background-color: rgba(255, 255, 255, 0);
  /* max-width: 100%; */
  margin-left: 25% !important;
  margin-right: 25% !important;
  z-index: 3;
}

.div_9 {
  background-color: #B1A296;
  height: 600px;
  margin-top: -450px;
  z-index: 1;
  content: "";
}

.div_9 {
  clip-path: polygon(
    0 60%,
    /* left top */ 100% 0,
    /* right top */ 100% 82%,
    /* right bottom */ 0 100% /* left bottom */
  );
}

.div_10 {
  height: 500px;
  background-image: url("/assets/IMPACT-BANNER.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  z-index: 2;
}

.div_10 {
  clip-path: polygon(
    0 18%,
    /* left top */ 100% 0,
    /* right top */ 100% 100%,
    /* right bottom */ 0 calc(100% - 6vw) /* left bottom */
  );
}

.div_11 {
  background-color: white;
  height: auto;
  margin-top: -250px;
  background-color: rgba(255, 255, 255, 0);
  /* max-width: 100%; */
  margin-left: 25% !important;
  margin-right: 25% !important;
  z-index: 3;
}

.div_12 {
  background-color: #938e94;
  height: 630px;
  margin-top: -480px;
  z-index: 1;
  content: "";
}

.div_12 {
  clip-path: polygon(
    0 60%,
    /* left top */ 100% 0,
    /* right top */ 100% 82%,
    /* right bottom */ 0 100% /* left bottom */
  );
}

.div_13 {
  height: 500px;
  background-image: url("/assets/MIDDLEMARKET-BANNER.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  z-index: 2;
}

.div_13 {
  clip-path: polygon(
    0 18%,
    /* left top */ 100% 0,
    /* right top */ 100% 100%,
    /* right bottom */ 0 calc(100% - 6vw) /* left bottom */
  );
}

.div_14 {
  background-color: white;
  height: auto;
  margin-top: -250px;
  background-color: rgba(255, 255, 255, 0);
  /* max-width: 100%; */
  margin-left: 33% !important;
  margin-right: 25% !important;
  z-index: 3;
}

.div_15 {
  background-color: #557A95;
  height: 620px;
  margin-top: -480px;
  z-index: 1;
  content: "";
}

.div_15 {
  clip-path: polygon(
    0 60%,
    /* left top */ 100% 0,
    /* right top */ 100% 82%,
    /* right bottom */ 0 100% /* left bottom */
  );
}

/* .card_image_height_dc {
  height: 272px;
  overflow: hidden;
} */

/* .card_image_height_hcr {
  height: 149px;
  overflow: hidden;
} */

/* .card_image_height_re {
  height: 240px;
  overflow: hidden;
} */

/* .card_image_height_ii {
  height: 248px;
  overflow: hidden;
} */

/* .card_image_height_mm {
  height: 400px;
  overflow: hidden;
} */

/* .card-deck {
  width: 100% !important;
} */

#management_card_ie_width {
  width: 100% !important;
}

.card-deck .card {
  display: block !important;
}
.assets_dc_header {
  font-size: 3.1rem;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  margin-top: 95px;
  padding-right: 30%;
  letter-spacing: 2px;
  text-shadow: 2px 2px 5px #313131;
}

.asset_header_para {
  margin-bottom: 3rem;
}

.cap_letters {
  text-transform: uppercase;
}

@media only screen and (max-width: 1000px) {
  .assets_container {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  .div_2 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .div_5 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .div_8 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .div_11 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .div_14 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.asset_disclosure {
  margin-top: 25px;
  padding-left: 25%;
  padding-right: 25%;
  margin-bottom: -50px;
}

@media only screen and (max-width: 750px) {
  .assets_hero_container {
    height: auto;
    overflow: hidden;
  }
  .assets_hero_text {
    text-align: center;
  }
  .asset_disclosure {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 520px) {
  .assets_hero_text {
    top: 10rem;
    font-size: 2.5rem;
}
}

@media only screen and (max-width: 600px) {
  .assets_dc_header {
    font-size: 2.1rem
}
}

/* @media only screen and (max-width: 991px) {
  .asset_card_solo {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }
} */


.asset_header {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  background-color: rgb(105, 160, 206);
  margin-bottom: 0;
  margin-top: 76px;
  font-weight: 400;
}

.asset_hero_img_container {
  height: 500px;
  overflow: hidden;
}

.asset_image_div {
  /* height: 400px; */
  /* background-color: rgb(255, 255, 255); */
  padding: 55px;
}

.asset_image_div_content {
  /* height: 220px; */
  /* background-image: linear-gradient(to bottom right, #a2bcb1, #1fc8db); */
  background-color: #142d53;
  color: rgb(214, 214, 214);
  /* margin-top: 5%; */
  /* border: 0.5px solid rgb(59, 104, 141); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.asset_div_content_wrapper {
  padding: 5px;
  /* background-color: white; */
  margin: 30px;
}

.asset_div_content_header {
  font-weight: 400;
  padding-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .asset_image_div {
    padding: 0;
  }
  .asset_header {
    padding-left: 10px;
    padding-right: 10px;
  }
  .asset_div_content_wrapper {
    margin: 10px;
    padding-top: 8px;
    padding-bottom: 5px;
  }
}



