.contact_container {
  margin-top: 145px;
}

.contact_map_container {
  height: 450px;
  background-color: rgb(98, 218, 192);
}

.contact_header {
  font-weight: 400;
}

.contact_info {
  font-size: 0.9em;
}

.contact_blue_text {
    color: #142d53
}

.contact_margin {
    margin-top: 55px;
    margin-bottom: 35px;
}
@media only screen and (max-width: 768px) {
  .contact_container {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .contact_header {
    margin-top: 20px;
  }
}

.career_row2 {
  margin-top: 20px;
  /* border: 3px solid black; */
}

.contact_first_vr {
  border-right: 1px solid rgb(179, 179, 179);
}

.contact_map_container_2 {
  margin-top: 77px;
}

.contact_container_2 {
  margin-top: 25px;
}

.contact_map_nopadding {
  padding: 0 !important;
}
